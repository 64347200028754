<template>
    <section class="blockElement space homeBanner bg-white">
        <div class="container">
            <div class="row justify-content-center column-md-reverse">
                <div class="col-12 col-lg-6 text-center">
                    <h1 class="bold mb-md-4">{{$t('home.text1')}} <span class="secondary">{{$t('home.text2')}}</span> {{$t('home.text3')}}</h1>
                    <h3 class="f-24 bold">{{$t('home.text4')}}</h3>
                    <p class="mb-md-4">{{$t('home.text5')}}</p>
                    <!-- <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn d-inline-flex zulu_btn gradient large">Join Now</router-link> -->
                    <a v-if="!store.user.access_token" :href="static_vars.domainURL+'register/'" class="button fillBtn d-inline-flex zulu_btn gradient large">{{$t('home.text80')}}</a>
                </div>
                <div class="col-12 col-lg-10 text-center">
                    <video style="width:100%;pointer-events: none;" width="100%" height="420" autoplay playsinline muted loop preload="auto" class="bannerVideo light">
                        <source src="/assets/video/Homepage-herobanner-v4.mp4" type="video/mp4" />
                        <source src="/assets/video/Homepage-herobanner-v4.ogg" type="video/ogg" />
                    </video>
                    <video style="width:100%;pointer-events: none;" width="100%" height="420" autoplay playsinline muted loop preload="auto" class="bannerVideo dark d-none">
                        <source src="/assets/video/Homepage-herobanner-dark.mp4" type="video/mp4" />
                        <source src="/assets/video/Homepage-herobanner-dark.ogg" type="video/ogg" />
                    </video>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space element2">
        <div class="container">
            <div class="row align-items-center mb-4">
                <div class="col-12 col-md-6 mobCenter">
                    <v-lazy-image width="426" height="327" class="d-block mx-auto" src="/assets/images/trading-is-hard-creative.png" :alt="$t('home.text81')" :title="$t('home.text82')" />
                </div>
                <div class="col-12 col-md-6 mobCenter">
                    <h2>
                        {{$t('home.text7')}}<br />
                        {{$t('home.text8')}}
                    </h2>
                    <ul class="listContent mt-md-3 mt-1">
                        <li><v-lazy-image width="19" class="me-2 d-block" src="/assets/images/clock.svg" :alt="$t('home.text83')" :title="$t('home.text84')" /> {{$t('home.text9')}}</li>
                        <li><v-lazy-image width="19" class="me-2 d-block" src="/assets/images/target.svg" :alt="$t('home.text85')" :title="$t('home.text86')" /> {{$t('home.text10')}}</li>
                    </ul>
                    <div class="d-flex align-items-center">
                        <p class="mb-0 me-md-2">{{$t('home.text11')}}<b>{{$t('home.text12')}}</b> {{$t('home.text13')}}</p>
                        <div class="tooltipbutton">
                            <span class="tooltiptext"> {{$t('home.text14')}}<span class="secondary">{{$t('home.text15')}}</span>) </span>
                            <vue-feather class="smallSize" type="alert-circle"></vue-feather>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex-row-reverse align-items-center">
                <div class="col-12 col-md-6 mobCenter">
                    <v-lazy-image width="352" height="414" class="d-block mx-auto" src="/assets/images/winner.png" :alt="$t('home.text81')" :title="$t('home.text82')" />
                </div>
                <div class="col-12 col-md-6 text-md-end mobCenter">
                    <h2>{{$t('home.text16')}}</h2>
                    <div class="d-flex align-items-end my-md-3 my-2">
                        <p class="mb-0 me-md-2">{{$t('home.text17')}}<b>{{$t('home.text18')}}</b>{{$t('home.text19')}}</p>
                        <div class="tooltipbutton">
                            <span class="tooltiptext">
                                {{$t('home.text20')}}
                            </span>
                            <vue-feather class="smallSize" type="alert-circle"></vue-feather>
                        </div>
                    </div>
                    <router-link class="mb-4 secondary d-flex align-items-center justify-content-end leftMob" to="/zulutrade-data">{{$t('home.text21')}} <i class="ms-2 fa fa-chevron-circle-right" aria-hidden="true"></i></router-link>
                    <div class="buttonColumn">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn hoverBtn">{{$t('home.text22')}}</router-link>
                        <a v-else class="button fillBtn hoverBtn" :href="static_vars.domainURL+'register/'">{{$t('home.text22')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement position-relative element3">
        <div class="container">
            <div class="row space bg-white boxed align-items-center py-md-5">
                <div class="col-12 col-md-6 max-300">
                    <v-lazy-image class="d-block mx-auto mh-auto" width="480" height="383" src="/assets/images/How-it-works-creative.png" :alt="$t('home.text87')" :title="$t('home.text88')"  />
                </div>
                <div class="col-12 col-md-6">
                    <h2 class="mb-md-4 mb-3">{{$t('home.text23')}}</h2>
                    <ul class="listCounter commonList">
                        <li><span class="medium">{{$t('home.text24')}}</span> {{$t('home.text25')}}</li>
                        <li><span class="medium">{{$t('home.text26')}}</span> {{$t('home.text27')}}</li>
                        <li><span class="medium">{{$t('home.text28')}} </span> {{$t('home.text29')}}</li>
                    </ul>
                    <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn hoverBtn">{{$t('home.text30')}}</router-link>
                    <a v-else class="button fillBtn hoverBtn" :href="static_vars.domainURL+'register/'">{{$t('home.text30')}}</a>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space element4 upPadding bg-white" v-if="Object.keys(store.topTrdaersList).length > 0 && store.topTrdaersList[0].result && store.topTrdaersList[0].result.length">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-md-4 mobCenter">
                    <h2>{{$t('home.text31')}}</h2>
                </div>
            </div>
            <div class="row mobDirection">
                <div class="col-12 col-md-9 mobCarousel pb-3 pb-md-0">
                    <div class="row">
                        <div class="col-md-4 col-6" v-for="item, index in  store.topTrdaersList[0].result.slice(0,3)" :key="index">
                            <div class="card h-100 overflow-hidden" v-show="item.trader && Object.keys(item.trader).length">
                                <div class="card-body px-0 pb-0" v-show="item.trader.profile && Object.keys(item.trader.profile).length > 0">
                                    <div class="text-center position-relative" v-show="item.trader.profile.name">
                                        <!-- <span class="top-avtar" v-if="item.trader.profile.countryName">
                                        <v-lazy-image width="100" :src="'/assets/images/icon/zuluicons/Flags/'+item.trader.profile.countryIsoCode.toLowerCase()+'.png'" :alt="item.trader.profile.countryName" :title="item.trader.profile.countryName">
                                        </span> -->
                                        <div class="vueRisk px-3 d-flex align-items-start justify-content-between">
                                            <p class="bTn mb-0 f-12 bold uppercase" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">
                                                {{$t('home.text32')}} {{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}
                                            </p>
                                            <!--yellowBtn, dashgreen, blueBtn-->
                                            <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                                <span class="circleImg d-inline-block mb-3">
                                                    <v-lazy-image width="70" height="70"
                                                        :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'"
                                                        :alt="item.trader.profile.name"
                                                        :title="item.trader.profile.name"
                                                    />
                                                </span>
                                            </router-link>
                                            <div class="d-flex align-items-center">
                                                <a href="javascript:void(0)" class="me-1" @click="AddWatchList(item)"  :class="{'disabled' : store.customerDetail?.readOnly}"><vue-feather type="star" size="19" :class="[{'filled' : isFollowed(item.trader.providerId)}]"></vue-feather></a>
                                                <div class="position-relative">
                                                    <a href="javascript:void(0)" class="iconButton ms-1" @click="(opendots != item.trader.profile.zuluAccountId) ? opendots = item.trader.profile.zuluAccountId : opendots = -1">
                                                        <i class="gray fa fa-ellipsis-v" aria-hidden="true"></i>
                                                    </a>
                                                    <ul class="dropdown-menu homeTrade" data-popper-placement="bottom-start" :class="[{ 'show': opendots ==  item.trader.profile.zuluAccountId }]">
                                                        <li><a class="f-12 dropdown-item" href="javascript:void(0);" @click="sharePop = item.trader.providerId">{{$t('home.text33')}}</a></li>
                                                        <li><router-link :to="{path:'/compare',query:{p:item.trader.profile.id}}" class="f-12 dropdown-item">{{$t('home.text34')}}</router-link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                            <div class="title">
                                                <a href="javascript:void(0)">
                                                    <h6 class="mb-1 f-16">{{ item.trader.profile.name }}</h6>
                                                </a>
                                                <div class="element d-flex align-items-center justify-content-center mb-3">
                                                    <div class="element d-flex align-items-center justify-content-center me-2" v-if="item.trader.overallStats.followers">
                                                        <p class="moregray f-12 mb-0 me-1">{{$t('home.text35')}}</p>
                                                        <p class="f-12 medium mb-0">{{ item.trader.overallStats.followers || 0}}</p>
                                                    </div>
                                                    <div class="element d-flex align-items-center justify-content-center" v-if="item.trader.overallStats && item.trader.overallStats.includedInWatchlist">
                                                        <p class="moregray f-12 mb-0 me-1">{{$t('home.text36')}}</p>
                                                        <p class="f-12 medium mb-0">{{ item.trader.overallStats.includedInWatchlist || 0 }}</p>
                                                    </div>
                                                </div>
                                                <div class="element">
                                                    <!-- <p class="f-12 mb-0">ROI</p> -->
                                                    <p class="mb-0 px-1 bold d-inline-flex" :class="ROIClass(item)" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                                        {{parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0 ? '+' : '-'}}{{
                                                        parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(1) || 0 }}%
                                                    </p>
                                                </div>
                                            </div>
                                        </router-link>
                                        <div class="chartElement" :id="'topTraders' + item.trader.profile.zuluAccountId + '_' + store.topTrdaersList[0].order" style="width: 100%; height: 126px; display: flex;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 ps-lg-5 mobCenter">
                    <h3 class="bold">{{$t('home.text37')}}</h3>
                    <p>{{$t('home.text38')}}</p>
                </div>
                <div class="col-12 mb-0 md-md-3">
                    <span class="border d-flex my-md-5 mb-3 w-100"></span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-3 pe-lg-5 mobCenter mb-0">
                    <h3 class="bold">{{$t('home.text39')}}</h3>
                    <p>{{$t('home.text40')}}</p>
                </div>
                <div class="col-12 col-md-9 mobCarousel pb-3 pb-md-0">
                    <div class="row">
                        <div class="col-md-4 col-6" v-for="item, index in  store.topTrdaersList[3].result.slice(0,3)" :key="index">
                            <div class="card h-100 overflow-hidden" v-show="item.trader && Object.keys(item.trader).length">
                                <div class="card-body px-0 pb-0" v-show="item.trader.profile && Object.keys(item.trader.profile).length > 0">
                                    <div class="text-center position-relative" v-show="item.trader.profile.name">
                                        <!-- <span class="top-avtar" v-if="item.trader.profile.countryName">
                                        <v-lazy-image width="100" :src="'/assets/images/icon/zuluicons/Flags/'+item.trader.profile.countryIsoCode.toLowerCase()+'.png'" :alt="item.trader.profile.countryName" :title="item.trader.profile.countryName">
                                        </span> -->
                                        <div class="vueRisk px-3 d-flex align-items-start justify-content-between">
                                            <p class="bTn mb-0 f-12 bold uppercase" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">
                                                {{$t('home.text32')}} {{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}
                                            </p>
                                            <!--yellowBtn, dashgreen, blueBtn-->
                                            <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                                <span class="circleImg d-inline-block mb-3">
                                                    <v-lazy-image width="70" height="70"
                                                        :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'"
                                                        :alt="item.trader.profile.name"
                                                        :title="item.trader.profile.name"
                                                    />
                                                </span>
                                            </router-link>
                                            <div class="d-flex align-items-center">
                                                <a href="javascript:void(0)" class="me-1" @click="AddWatchList(item)"  :class="{'disabled' : store.customerDetail?.readOnly}"><vue-feather type="star" size="19" :class="[{'filled' : isFollowed(item.trader.providerId)}]"></vue-feather></a>
                                                <div class="position-relative">
                                                    <a href="javascript:void(0)" class="iconButton ms-1" @click="(opendots != item.trader.profile.zuluAccountId) ? opendots = item.trader.profile.zuluAccountId : opendots = -1">
                                                        <i class="gray fa fa-ellipsis-v" aria-hidden="true"></i>
                                                    </a>
                                                    <ul class="dropdown-menu homeTrade" data-popper-placement="bottom-start" :class="[{ 'show': opendots ==  item.trader.profile.zuluAccountId }]">
                                                        <li><a class="f-12 dropdown-item" href="javascript:void(0);" @click="sharePop = item.trader.providerId">{{$t('home.text33')}}</a></li>
                                                        <li><router-link :to="{path:'/compare',query:{p:item.trader.profile.id}}" class="f-12 dropdown-item">{{$t('home.text34')}}</router-link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                        <div class="title">
                                            <a href="javascript:void(0)">
                                                    <h6 class="mb-1 f-16">{{ item.trader.profile.name }}</h6>
                                            </a>
                                            <div class="element d-flex align-items-center justify-content-center mb-3">
                                                <div class="element d-flex align-items-center justify-content-center me-2" v-if="item.trader.overallStats.followers">
                                                    <p class="moregray f-12 mb-0 me-1">{{$t('home.text35')}}</p>
                                                    <p class="f-12 medium mb-0">{{ item.trader.overallStats.followers || 0}}</p>
                                                </div>
                                                <div class="element d-flex align-items-center justify-content-center" v-if="item.trader.overallStats && item.trader.overallStats.includedInWatchlist">
                                                    <p class="moregray f-12 mb-0 me-1">{{$t('home.text36')}}</p>
                                                    <p class="f-12 medium mb-0">{{ item.trader.overallStats.includedInWatchlist || 0 }}</p>
                                                </div>
                                            </div>
                                            <div class="element">
                                                <!-- <p class="f-12 mb-0">ROI</p> -->
                                                <p class="mb-0 px-1 bold d-inline-flex" :class="ROIClass(item)" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                                    {{parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0 ? '+' : '-'}}{{
                                                    parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(1) || 0 }}%
                                                </p>
                                            </div>
                                        </div>
                                    </router-link>
                                        <div class="chartElement" :id="'topTraders' + item.trader.profile.zuluAccountId + '_' + store.topTrdaersList[3].order" style="width: 100%; height: 126px; display: flex;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="blockElement space position-relative bg-white element5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 max-300">
                    <v-lazy-image class="d-block mx-auto" src="/assets/images/How-it-works-creative.png" alt="How does zulutrade work?" title="Traders join ZuluTrade" />
                </div>
                <div class="col-12 col-lg-6">
                    <h2 class="mb-md-4 mb-2">No time to hand pick Traders? Go autopilot with Combos+!</h2>
                    <p>If you’re overwhelmed by trader choices, let us do the selection & management for you. Simply select the Combo+ that you want to follow and we’ll take it from there!</p>
                    <ul class="arrowLeft commonList">
                        <li><span class="medium d-block">Win-Win & transparent</span> Pay traders only for generated profits</li>
                        <li><span class="medium d-block">Simple & Worry-free</span> Automatic Monitoring & Portfolio rebalancing from our team</li>
                        <li><span class="medium d-block">Professionally Managed & Licensed </span> By our Certified Portfolio Management Team</li>
                    </ul>
                    <a class="button fillBtn hoverBtn" :href="static_vars.domainURL+'register/'">Create Account</a>
                </div>
            </div>
        </div>
    </section> -->
    <market />
    <section class="blockElement space bg-white">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-12 mb-md-4">
                            <h2> {{$t('home.text41')}} <span class="secondary"> {{$t('home.text42')}}</span></h2>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="ourAward">
                                <div class="position-relative maskIcon">
                                    <span class="f-12 semibold">{{$t('home.text43')}}</span>  
                                    <span class="f-12 semibold">{{$t('home.text44')}}</span>
                                </div>
                                <h4>{{$t('home.text45')}} <span>{{$t('home.text46')}}</span></h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="ourAward">
                                <div class="position-relative maskIcon">
                                    <span class="f-12 semibold">{{$t('home.text47')}}</span>  
                                    <span class="f-12 semibold">{{$t('home.text48')}}</span>
                                </div>
                                <h4>{{$t('home.text49')}} <span>{{$t('home.text50')}}</span></h4>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="ourAward">
                                <div class="position-relative maskIcon">
                                    <span class="f-12 semibold">{{$t('home.text51')}}</span>  
                                    <span class="f-12 semibold">{{$t('home.text52')}}</span>
                                </div>
                                <h4>{{$t('home.text53')}} <span>{{$t('home.text54')}}</span></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section class="blockElement space element7">
        <div class="container">
            <div class="row zuluTradeWhy">
                <div class="col-12 mb-4 mb-md-5">
                    <h2>{{$t('home.text55')}}</h2>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-2"><v-lazy-image width="100" height="100" class="d-block" src="/assets/images/wz-transparency.png" :alt="$t('home.text89')" :title="$t('home.text90')" /></span>
                        <h5 class="bold">{{$t('home.text56')}}</h5>
                        <p>{{$t('home.text57')}}</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-2"><v-lazy-image width="100" height="100" class="d-block" src="/assets/images/wz-innovation.png" :alt="$t('home.text91')" :title="$t('home.text92')" /></span>
                        <h5 class="bold">{{$t('home.text58')}}</h5>
                        <p>{{$t('home.text59')}}</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-2"><v-lazy-image width="100" height="100" class="d-block" src="/assets/images/wz-learn-by-copying.png" :alt="$t('home.text93')" :title="$t('home.text94')" /></span>
                        <h5 class="bold">{{$t('home.text60')}}</h5>
                        <p>{{$t('home.text61')}}</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div class="card-body">
                        <span class="d-block mb-2"><v-lazy-image width="100" height="100" class="d-block" src="/assets/images/wz-customer-oriented.png" :alt="$t('home.text95')" :title="$t('home.text96')" /></span>
                        <h5 class="bold">{{$t('home.text62')}}</h5>
                        <p>{{$t('home.text63')}}</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div class="card-body">
                        <span class="d-block mb-2"><v-lazy-image width="100" height="100" class="d-block" src="/assets/images/wz-trading-tools.png" :alt="$t('home.text97')" :title="$t('home.text98')" /></span>
                        <h5 class="bold">{{$t('home.text64')}}</h5>
                        <p>{{$t('home.text65')}} </p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="card-body">
                        <span class="d-block mb-2"><v-lazy-image width="100" height="100" class="d-block" src="/assets/images/uniqueAdvanced.svg" :alt="$t('home.text99')" :title="$t('home.text100')" /></span>
                        <h5 class="bold">{{$t('home.text66')}}</h5>
                        <p>{{$t('home.text67')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="blockElement space element8">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h2>Partner with Top Brokers</h2>
                    <p>To kick off your trading journey with us, you will need to connect your account with a Trading Broker.</p>
                    <p>Don't have a Broker? Don't worry! We partner with the most reliable Brokers worldwide, giving you the option to select one that best suits your needs.</p>
                    <a href="javascript:void(0)" class="secondary bold">See all Brokers</a>
                </div>
                <div class="col-12 col-md-6">
                    <ul class="clientLogo d-flex align-items-center flex-wrap">
                        <li>
                            <a href="javascript:void(0)"> <v-lazy-image src="/assets/images/clientLogo/fxview.png" alt="FXView" title="Fxview" /></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"> <v-lazy-image src="/assets/images/clientLogo/fxcm.png" alt="fxcm" title="fxcm" /></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"> <v-lazy-image src="/assets/images/clientLogo/AvaTrade.png" alt="AvaTrade" title="AvaTrade" /></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"> <v-lazy-image src="/assets/images/clientLogo/Weltrade.png" alt="Weltrade" title="Weltrade" /></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"> <v-lazy-image src="/assets/images/clientLogo/everfx.png" alt="everfx" title="everfx" /></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="f-16 bold link transparent border"> Show All</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space zuluTradeAccount bg-white">
        <div class="container max-100 p-0">
            <div class="row align-items-center">
                <div class="col-12 col-lg-5">
                    <ul class="contentList">
                        <li>
                            <span><v-lazy-image src="/assets/images/form-icon-world.png" alt="Traders from Brokers around the world" title="Copy Top Traders from Brokers around the world" /></span>
                            <p class="mb-0">Copy Top Traders from Brokers around the world</p>
                        </li>
                        <li>
                            <span><v-lazy-image src="/assets/images/form-icon-portfolio.png" alt="Hustle-free Investing" title="Copy Top Traders from Brokers around the world" /></span>
                            <p class="mb-0">Hustle-free Investing using our Portfolio Management Service with Combos+</p>
                        </li>
                        <li>
                            <span><v-lazy-image src="/assets/images/form-icon-low-commissions.png" alt="Low Commissions" title="Transparent Fair pricing - compensate traders with your earnings" /></span>
                            <p class="mb-0">Transparent Fair pricing - compensate traders with your earnings</p>
                        </li>
                        <li>
                            <span><v-lazy-image src="/assets/images/form-icon-support.png" alt="Highly rated Customer Service" title="Highly rated 24/5 Customer Service motivated for your success" /></span>
                            <p class="mb-0">Highly rated 24/5 Customer Service motivated for your success</p>
                        </li>
                        <li>
                            <span><v-lazy-image src="/assets/images/form-icon-lock.png" alt="Licensed and Regulated" title="Licensed and Regulated in the EU, Japan and USA" /></span>
                            <p class="mb-0">Licensed and Regulated in the EU, Japan and USA</p>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-7">
                    <div class="bg-white boxed px-md-5 py-md-4">
                        <h2 class="text-center bold mb-md-4">Join us at ZuluTrade</h2>
                        <form class="inputForm">
                            <h3 class="text-center mb-md-5 mb-4">Create an account</h3>
                            <a href="javascript:void(0)" class="borderButton d-flex align-items-center mb-2">
                                <v-lazy-image src="/assets/images/google-login.svg" loading="lazy" width="24" height="24" alt="google" title="Google Login" /><span>Continue with Google</span>
                            </a>
                            <a href="javascript:void(0)" class="borderButton d-flex align-items-center mb-2">
                                <v-lazy-image src="/assets/images/mac-login.svg" loading="lazy" width="24" height="24" alt="Apple" title="Continue with Apple" /><span>Continue with Apple</span>
                            </a>
                            <div class="text-center my-4">
                                <p class="or medium text-center">OR</p>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Username or email" autocomplete="off" />
                            </div>
                            <div class="form-group mb-4">
                                <div class="position-relative iconInput mb-3">
                                    <input type="password" class="form-control" placeholder="Enter Password" autocomplete="off" />
                                    <vue-feather class="position-absolute eye pointer" type="eye-off" size="23"></vue-feather>
                                    <div class="tooltipbutton position-absolute alertInput">
                                        <span class="tooltiptext">
                                            <div class="strenghtPassword">
                                                <h6>Password must be</h6>
                                                <ul class="passwordStrenth">
                                                    <li>1 uppercase character</li>
                                                    <li>8 or more characters</li>
                                                    <li class="red">at least 1 number</li>
                                                    <li class="green">at least 1 letter</li>
                                                    <li>at least 1 special character</li>
                                                </ul>
                                            </div>
                                        </span>
                                        <vue-feather class="smallSize" type="alert-circle"></vue-feather>
                                    </div>
                                </div>
                            </div>
                            <div class="buttonElement mb-3">
                                <a href="javascript:void(0)" class="button fillBtn zulu_btn gradient large">Create account</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <sharePop :shareURL="sharePop" :close="()=>sharePop = ''" v-if="sharePop" :shareType="'leaderProfile'"></sharePop>
    <loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import market from "./market-instruments";
    import moment from "moment";
    import sharePop from "@/views/social-feed/share-pop";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                opendots: false,
                showLoginPopup : false,
                sharePop: '',
            };
        },
        components: {
            market, sharePop
        },
        watch : {
            'store.flavordata' (){
                if(Object.keys(this.store.flavordata).length && this.store.flavordata?.flavorId){
                    this.getTopTradersList();
                }
            },
            // 'store.userSelectedAccount' (){
            //     if(this.store.user.access_token && Object.keys(this.store.userSelectedAccount).length){
            //         this.allCategoryPrices();
            //         this.callSortingCurrencies();
            //     }
            // },
            // 'store.allTypeAccount'(){
            //     if(this.store.user.access_token && this.store.allTypeAccount){
            //         this.allCategoryPrices();
            //         this.callSortingCurrencies();
            //     }
            // }
        },
        methods: {
            AddWatchList(data) {
                if (this.store.user.access_token) {
                    this.showLoginPopup = false;
                    if (data) {
                        if (this.isFollowed(data.trader.providerId)) {
                            this.addWatchList(data, "DELETE");
                        } else {
                            this.addWatchList(data, "ADD");
                        }
                    } else {
                        this.addCustomView();
                    }
                } else {
                    this.showLoginPopup = true;
                }
            },
            addWatchList(item, type) {
                let payload = {
                    item: item.trader.providerId,
                    type: "LEADER",
                };
                let json = {};
                if (type == "ADD") {
                    json["id"] = item.trader.providerId;
                    json["type"] = "LEADER";
                    json["name"] = item.trader.profile.name;
                    json["roi"] = 0;
                    json["chart"] = {};
                    let data = this.store.leaderWatchList;
                    data.push(json);
                    this.store.$patch({ leaderWatchList: data });
                } else if (type == "DELETE") {
                    let data = this.store.leaderWatchList.filter((i) => parseInt(i.id) != parseInt(item.trader.providerId));
                    this.store.$patch({ leaderWatchList: data });
                }
                this.store.addToWatchList(payload, false, this, type);
            },
            callWatchList() {
                this.store.getWatchList({}, false,"180");
            },
            isFollowed(id) {
                if (this.store.leaderWatchList.length) {
                    let data = this.store.leaderWatchList.map((i) => i.id);
                    if (data.includes(parseInt(id))) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            getTopTradersList() {
                this.store.callTopTradersList({}, false).then(() => {
                    if (Object.keys(this.store.topTrdaersList).length && this.store.topTrdaersList[0].result && this.store.topTrdaersList[0].result.length) {
                        this.store.topTrdaersList[0].result.slice(0, 3).forEach((item) => {
                            if (
                                item.trader &&
                                Object.keys(item.trader).length &&
                                item.trader.profile &&
                                Object.keys(item.trader.profile).length &&
                                item.trader.profile.zuluAccountId &&
                                item.trader.timeFrameGraph &&
                                item.trader.timeFrameGraph.series &&
                                item.trader.timeFrameGraph.series.length &&
                                item.trader.timeFrameGraph.series[0].data &&
                                item.trader.timeFrameGraph.series[0].data.length
                            ) {
                                am5.ready(() => {
                                    this.tradersChart(item.trader.profile.zuluAccountId + "_" + this.store.topTrdaersList[0].order, item.trader.timeFrameGraph.series[0].data);
                                });
                            }
                        });
                        this.store.topTrdaersList[3].result.slice(0, 3).forEach((item) => {
                            if (
                                item.trader &&
                                Object.keys(item.trader).length &&
                                item.trader.profile &&
                                Object.keys(item.trader.profile).length &&
                                item.trader.profile.zuluAccountId &&
                                item.trader.timeFrameGraph &&
                                item.trader.timeFrameGraph.series &&
                                item.trader.timeFrameGraph.series.length &&
                                item.trader.timeFrameGraph.series[0].data &&
                                item.trader.timeFrameGraph.series[0].data.length
                            ) {
                                am5.ready(() => {
                                    this.tradersAnotherChart(item.trader.profile.zuluAccountId + "_" + this.store.topTrdaersList[3].order, item.trader.timeFrameGraph.series[0].data);
                                });
                            }
                        });
                    }
                });
            },
            tradersAnotherChart(id, chartdata) {
                let chartId = "topTraders" + id;
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === chartId);
                d?.dispose();
                let root = am5.Root.new(chartId);
                root.setThemes([am5themes_Animated.new(root)]);

                root.dateFormatter.setAll({
                    dateFormat:"dd MMM yyyy",
                    dateFields: ["valueX"]
                });
                root.numberFormatter.set("numberFormat", "#,###.00");

                // Create chart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        visible: false,
                        groupData: true,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minGridDistance: 10,
                        }),
                        // tooltip: am5.Tooltip.new(root, {})
                    })
                );

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        visible: false,
                        strictMinMax: true,
                        baseValue : -1000,
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                xAxis.get("renderer").grid.template.set("forceHidden", true);
                yAxis.get("renderer").grid.template.set("forceHidden", true);
                // let yRenderer = yAxis.get("renderer");
                // yRenderer.grid.template.setAll({
                //     stroke: am5.color(0xFF0000),
                //     strokeWidth: 2
                // });

                //let range = yAxis.createAxisRange(rangeDataItem);

                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                // Add series
                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            // pointerOrientation: "horizontal",
                            labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]ROI(%)[/] : {valueY}",
                        }),
                    })
                );

                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [
                            {
                                opacity: 0.1,
                            },
                            {
                                opacity: 0.04,
                            },
                        ],
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set up data processor to parse string dates
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["x"],
                });

                // series.bullets.push(function() {
                //     let circle = am5.Circle.new(root, {
                //         radius: 4,
                //         // fill: root.interfaceColors.get("background"),
                //         // stroke: this.series.get("fill"),
                //         strokeWidth: 2
                //     })

                //     return am5.Bullet.new(root, {
                //         sprite: circle
                //     })
                // });
                // series.bullets.push(function(root) {
                //   return am5.Bullet.new(root, {
                //     sprite: am5.Circle.new(root, {
                //       radius: 4,
                //       fill: series.get("fill")
                //     })
                //   });
                // });
                // Add cursor
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        // xAxis: xAxis,
                        // behavior: "zoomX"
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                // chart.zoomOutButton.set("forceHidden", true);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });

                // Set data
                var data = chartdata;
                series.data.setAll(data);

                // Make stuff animate on load
                series.appear(1000);
                chart.appear(1000, 100);
            },
            tradersChart(id, chartdata) {
                //console.log(chartdata[chartdata.length-1])
                let chartId = "topTraders" + id;
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === chartId);
                d?.dispose();
                let root = am5.Root.new(chartId);

                root.setThemes([am5themes_Animated.new(root)]);
                root.numberFormatter.set("numberFormat", "#,###.00");

                root.dateFormatter.setAll({
                    dateFormat: "dd MMM yyyy",
                    dateFields: ["valueX"],
                });

                // Create chart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        visible: false,
                        groupData: true,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minGridDistance: 10,
                        }),
                        // tooltip: am5.Tooltip.new(root, {})
                    })
                );

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        visible: false,
                        strictMinMax: true,
                        baseValue : -1000,
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                xAxis.get("renderer").grid.template.set("forceHidden", true);
                yAxis.get("renderer").grid.template.set("forceHidden", true);
                // let yRenderer = yAxis.get("renderer");
                // yRenderer.grid.template.setAll({
                //     stroke: am5.color(0xFF0000),
                //     strokeWidth: 2
                // });

                //let range = yAxis.createAxisRange(rangeDataItem);

                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                // Add series
                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            // pointerOrientation: "horizontal",
                            labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]ROI(%)[/] : {valueY}",
                        }),
                    })
                );

                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [
                            {
                                opacity: 0.1,
                            },
                            {
                                opacity: 0.04,
                            },
                        ],
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set up data processor to parse string dates
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["x"],
                });

                // series.bullets.push(function() {
                //     let circle = am5.Circle.new(root, {
                //         radius: 4,
                //         // fill: root.interfaceColors.get("background"),
                //         // stroke: this.series.get("fill"),
                //         strokeWidth: 2
                //     })

                //     return am5.Bullet.new(root, {
                //         sprite: circle
                //     })
                // });
                // series.bullets.push(function(root) {
                //   return am5.Bullet.new(root, {
                //     sprite: am5.Circle.new(root, {
                //       radius: 4,
                //       fill: series.get("fill")
                //     })
                //   });
                // });
                // Add cursor
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        // xAxis: xAxis,
                        // behavior: "zoomX"
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                // chart.zoomOutButton.set("forceHidden", true);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });

                // Set data
                var data = chartdata;
                series.data.setAll(data);

                // Make stuff animate on load
                series.appear(1000);
                chart.appear(1000, 100);
            },
            ROIClass(item) {
                if (parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0) {
                    return "greenView";
                } else {
                    return "redView";
                }
            },
            allCategoryPrices() {
                let formData = {
                    from: moment().subtract(2, "week").format("YYYY-MM-DD"),
                    to: moment().format("YYYY-MM-DD"),
                    limit: 9,
                    page: 1,
                };

                let forexPayload = {
                    categories: [1],
                    ...formData,
                };
                let commoditiesPayload = {
                    categories: [3],
                    ...formData,
                };
                let cryptoPayload = {
                    categories: [4],
                    ...formData,
                };
                let indicesPayload = {
                    categories: [7],
                    ...formData,
                };
                let stocksPayload = {
                    categories: [8],
                    ...formData,
                };
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(forexPayload, false);
                }
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(commoditiesPayload, false);
                }
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(cryptoPayload, false);
                }
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(indicesPayload, false);
                }
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(stocksPayload, false);
                }
            },
            callSortingCurrencies() {
                let formData = {
                    page: 1,
                    limit: 9,
                };
                let topWinning = {
                    sortingStrategy: "TOP_WINNERS",
                    ...formData,
                };
                let topLoser = {
                    sortingStrategy: "TOP_LOSERS",
                    ...formData,
                };
                let mostPopluar = {
                    sortingStrategy: "MOST_POPULAR",
                    ...formData,
                };
                this.store.getRatePageCategories(topWinning, false);
                this.store.getRatePageCategories(topLoser, false);
                this.store.getRatePageCategories(mostPopluar, false);
            },
        },
        mounted(){
            if(Object.keys(this.store.flavordata).length && this.store.flavordata.flavorId){
                this.getTopTradersList();
            }
        },
        created() {
            // this.allCategoryPrices();
            // this.callSortingCurrencies();
        },
    };
</script>

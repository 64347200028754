<template>
    <section class="blockElement space position-relative element6">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-3 mb-4 mb-lg-0">
                    <h2 class="mb-4">{{$t('home.text68')}}</h2>
                    <p>{{$t('home.text69')}}</p>
                    <p>{{$t('home.text70')}}</p>
                    <router-link to="/markets" class="button fillBtn hoverBtn">{{$t('home.text71')}}</router-link>
                </div>
                <div class="col-12 col-lg-9">
                    <div class="bg-white boxed position-relative megaDropMenu mobPad">
                        <a @click.prevent="dropButton = !dropButton" href="javascript:void(0)" class="ms-3 button fillBtn border-button zulu_btn mobButoon d-inline-flex align-items-center justify-content-between d-md-none"> {{tabTitle[tab]}} <vue-feather size="18" type="chevron-down" class="ms-3"></vue-feather></a>
                        <ul id="tabs-nav" class="zuluTabs large d-flex large align-items-center mb-0" :class="dropButton?'active':''">
                            <li><a class="medium" href="javascript:void(0)" @click="changeTab(1);dropButton=false" :class="[{'active':tab == 1}]">{{$t('home.text72')}}</a></li>
                            <li><a class="medium" href="javascript:void(0)" @click="changeTab(2);dropButton=false" :class="[{'active':tab == 2}]">{{$t('home.text73')}}</a></li>
                            <li><a class="medium" href="javascript:void(0)" @click="changeTab(3);dropButton=false" :class="[{'active':tab == 3}]">{{$t('home.text74')}}</a></li>
                            <li><a class="medium" href="javascript:void(0)" @click="changeTab(4);dropButton=false" :class="[{'active':tab == 4}]">{{$t('home.text75')}}</a></li>
                            <li><a class="medium" href="javascript:void(0)" @click="changeTab(5);dropButton=false" :class="[{'active':tab == 5}]">{{$t('home.text76')}}</a></li>
                            <li><a class="medium" href="javascript:void(0)" @click="changeTab(6);dropButton=false" :class="[{'active':tab == 6}]">{{$t('home.text77')}}</a></li>
                        </ul>
                        <div class="tradeManually mobCarousel pb-3 pb-md-0 my-4 hjk">
                            <div class="row g-3" v-if="getinstrumentsData.length">
                                <div class="col-6 col-xl-4" v-for="value,key in getinstrumentsData.slice(0,9)" :key="key">
                                    <div class="card customTradeAdd shadow-none h-100">
                                        <div class="card-body px-0">
                                            <div class="text-center position-relative">
                                                <div class="vueRisk">
                                                    <router-link :to="{ name: 'rates', query: { insID: value.id }}" class="d-flex align-items-center justify-content-center mb-3 vueRisk">
                                                    <v-lazy-image
                                                        width="30"
                                                        height="30"
                                                        class="euCurrency"
                                                        :src="static_vars.marketImageSURL+value.symbol.toUpperCase().replace(/\//g, '')+'.svg'"
                                                        :alt="value.symbol"
                                                        :title="value.symbol"
                                                        @error="handleImgErr($event)"
                                                    />
                                                    </router-link>
                                                    <a class="position-absolute star" href="javascript:void(0)" :class="{'disabled' : store.customerDetail?.readOnly}"  @click="AddWatchList(value,'MARKET')"><vue-feather type="star" size="19" :class="[{'filled' : isFollowed(value.id)}]"></vue-feather></a>
                                                </div>
                                                <div class="title">
                                                    <router-link :to="{ name: 'rates', query: { insID: value.id }}" class="mt-2 mb-3 d-block">
                                                        <h6 class="mb-0 medium f-18">{{value.symbol}}</h6>
                                                    </router-link>
                                                    <div class="element px-3">
                                                        <div class="elementHub d-flex align-items-center justify-content-between" v-if="value.symbol in store.allPrices">
                                                            <p class="f-20 mb-1 bold me-1 w-50" v-if="store.allPrices[value.symbol].buyPrice" :class="[{'buy' : store.allPrices[value.symbol].buyPrice > store.allPrices[value.symbol].o_b}]">
                                                                {{parseFloat(store.allPrices[value.symbol].buyPrice).toFixed(3)}}<sub class="f-10">{{getLastTwoDigit(store.allPrices[value.symbol].buyPrice)}}</sub>
                                                            </p>
                                                            <p class="f-20 mb-1 bold me-1 w-50" v-if="store.allPrices[value.symbol].sellPrice" :class="[{'sell' : store.allPrices[value.symbol].sellPrice < store.allPrices[value.symbol].o_s}]">
                                                                {{parseFloat(store.allPrices[value.symbol].sellPrice).toFixed(3)}}<sub class="f-10">{{getLastTwoDigit(store.allPrices[value.symbol].sellPrice)}}</sub>
                                                            </p>
                                                        </div>
                                                        <div class="elementHub d-flex align-items-center justify-content-center" v-else>
                                                            <p class="mb-0 bold me-2">{{value.currentPrice}}</p>
                                                            <p class="mb-0 px-1 medium d-inline-flex" :class="(parseFloat(value.lastChangePercentage) >= 0.0) ? 'greenView' : 'redView'">
                                                                {{parseFloat(value.lastChangePercentage) >= 0.0 ? '+' : ''}}{{parseFloat(value.lastChangePercentage).toFixed(2) || 0}}%
                                                            </p>
                                                        </div>

                                                        <div class="elementHub d-flex align-items-center" v-if="value.symbol in store.allPrices">
                                                            <p class="mb-0 px-1 green w-50 midgray bold f-12">{{$t('home.text78')}}</p>
                                                            <p class="mb-0 px-1 red w-50 midgray bold f-12">{{$t('home.text79')}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-12">
                                    <Nodata></Nodata>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
     <loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                tab: 1,
                dropButton:false,
                instrumentsList: {
                    1: "mostPopular",
                    2: "1",
                    3: "3",
                    4: "7",
                    5: "8",
                    6: "4",
                },
                tabTitle: {
                    1: this.$t('home.text72'),
                    2: this.$t('home.text73'),
                    3: this.$t('home.text74'),
                    4: this.$t('home.text75'),
                    5: this.$t('home.text76'),
                    6: this.$t('home.text77'),
                },
                errImage: [],
                showLoginPopup : false
            };
        },
        methods: {
            getLastTwoDigit(price) {
                if (price) {
                    price = price.toString();
                    if (price.split(".")[1] && price.split(".")[1].length < 5) {
                        return "";
                    } else if (price.split(".")[1] && price.split(".")[1].length >= 5) {
                        return price.split(".")[1].slice(price.split(".")[1].length - 2, price.split(".")[1].length);
                    }
                }
            },
            changeTab(val) {
                this.tab = parseInt(val);
            },
            handleImgErr(val) {
                var fullSrc = val.target.src.split(".");
                var src = fullSrc[fullSrc.length - 2].split("/");
                var img = src[src.length - 1];
                if (!this.errImage.includes(img)) {
                    this.errImage.push(img);
                }
            },
            isImage(img) {
                return this.errImage.includes(img) ? false : true;
            },
            callWatchList() {
                this.store.getWatchList({}, false,"1");
            },
            isFollowed(id) {
                if (this.store.watchList.length) {
                    let data = this.store.watchList.map((i) => i.id);
                    if (data.includes(parseInt(id))) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            AddWatchList(item, type) {
                if (!this.store.user.access_token) {
                    this.showLoginPopup = true;
                } else {
                    this.showLoginPopup = false;
                    if (this.isFollowed(item.id)) {
                        this.addWatchList(item, "DELETE", type);
                    } else {
                        this.addWatchList(item, "ADD", type);
                    }
                }
            },
            addWatchList(item, type, from) {
                let payload = {
                    item: item.id,
                    type: from,
                };
                let json = {};
                if (type == "ADD") {
                    json["id"] = item.id;
                    json["type"] = "MARKET";
                    json["currentPrice"] = item.currentPrice;
                    json["dailyPriceChangePercentage"] = item.dailyPriceChangePercentage;
                    json["description"] = item.description;
                    json["name"] = item.name;
                    json["chart"] = {};
                    let data = this.store.watchList;
                    data.push(json);
                    this.store.$patch({ watchList: data });
                } else if (type == "DELETE") {
                    let data = this.store.watchList.filter((i) => parseInt(i.id) != parseInt(item.id));
                    this.store.$patch({ watchList: data });
                }
                this.store.addToWatchList(payload, false, this, type);
            },
        },
        computed: {
            getinstrumentsData() {
                if(Object.keys(this.store.categoryWiseMarket).length){
                    let data = (this.tab == 1) ? this.store.categoryWiseMarket?.mostPopular : this.store.categoryWiseMarket?.marketsByCategory?.[this.instrumentsList[this.tab]];
                    if (data?.length) {
                        return data;
                    } else {
                        return [];
                    }
                }else{
                    return []
                }
            },
        },
    };
</script>
